import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';


function AboutPage(props) {

    return(
        <div>
            <Hero title={props.title} />

            <Content>
            <p>Hello, my name is Zachary Welch and I'm currently a computer science student with experience in controls engineering. </p>

            <p>My Intrest are in Computer Architecture, Electrical Engineering, and Security.</p>

            <p>In my free time I dabble in martial arts, music, side projects.</p>

            <p>As a child I developed a habit of dismantling things and putting them back together in hopes to learn the inner workings of machines.
                This lead me to purse thigns like computer science and electrical engineering.</p>
            
            <h3>Future Projects 2022</h3>
            <ul>
                <li>RC Solar Plane Using Rasberry Pi</li>
                <li>Kernal Mode Game Modifications</li>
                <li>Linux and Windows Device Drivers</li>
            </ul>

            <h3>Resources I Use To Learn Programming</h3>
            <ul>
                <li><a href="https://www.geeksforgeeks.org">Geeks For Geeks</a></li>
                <li><a href="https://www.hackerrank.com">Hacker Rank</a></li>
                <li><a href="https://leetcode.com">Leet Code</a></li>
            </ul>


            <h3>Inspirations</h3>
            <ul>
                <li><a href="https://www.youtube.com/c/DevonCrawford">Devon Crawford</a></li>
                <li><a href="https://www.youtube.com/channel/UCKCTmact-90hXpV2ns8GSsA">Dev Duck</a></li>
                <li><a href="https://www.youtube.com/channel/UCUmLRMERmJrmUtgnbFfknAg">Randall</a></li>
            </ul>

            </Content>
        </div>
    );

}

export default AboutPage;